import holteLogo from "./start-overlay-logo-holte.html";
import holteAsEgLogo from "./start-overlay-logo-holte-as-eg.html";
import egLogo from "./start-overlay-logo-eg.html";

void ((window) => {
  const countryCode = window.localStorage.getItem("UserCountryCode");
  const useHolteLogo = countryCode === "no" || countryCode === "pl";

  if (countryCode) {
    const logoHtml = getLogoHtml(countryCode);
    const logoContainer = window.document.getElementsByClassName("start-overlay-logo")[0];
    logoContainer.innerHTML = logoHtml;

    const prefix = useHolteLogo ? "holte" : "eg";
    const favicons = [
      { rel: "apple-touch-icon", hrefSelector: "apple-touch-icon.png", sizes: "180x180" },
      { rel: "icon", hrefSelector: "favicon-32x32.png", sizes: "32x32" },
      { rel: "icon", hrefSelector: "favicon-16x16.png", sizes: "16x16" },
      { rel: "mask-icon", hrefSelector: "safari-pinned-tab.svg", color: "#123c47" },
    ];

    favicons.forEach((item) => {
      const link = document.createElement("link");
      link.rel = item.rel;
      link.href = `/${prefix}-${item.hrefSelector}`;

      if (item.sizes) {
        link.setAttribute("sizes", item.sizes);
      }

      if (item.color && useHolteLogo) {
        link.setAttribute("color", item.color);
      }

      document.getElementsByTagName("head")[0].appendChild(link);
    });
  }
})(window);

function getLogoHtml(countryCode: string) {
  switch (countryCode) {
    case "no":
      return holteAsEgLogo;
    case "dk":
      return egLogo;
    default:
      return holteLogo;
  }
}
